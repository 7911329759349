import slotRegularDiagonal from "../images/slotRegularDiagonal.svg";
import slotRegularTop from "../images/slotRegularTop.svg";
import slotRegularRight from "../images/slotRegularRight.svg";
import slotBigDown from "../images/slotBigDown.svg";
import slotBigLeft from "../images/slotBigLeft.svg";
import slotBigRight from "../images/slotBigRight.svg";

const PlateIconsList = ({
  iconsList,
  cardRef,
  selectedIconIndex,
  handleIconClick,
  step,
}) => {
  const getMarginLeft = (rows, index, icon, iconsLength) => {
    if (index === 0 || (rows === 2 && index === Math.ceil(iconsLength / 2))) {
      return icon.firstMarginLeft;
    }
    if (
      rows === 3 &&
      (index === Math.ceil(iconsLength / 3) ||
        index === Math.ceil((iconsLength / 3) * 2))
    ) {
      return icon.firstMarginLeft;
    }
    return icon.marginLeft;
  };

  const getMarginTop = (rows, index, icon, iconsLength) => {
    if (rows === 2 && index > Math.ceil(iconsLength / 2 - 1)) {
      return icon.secondRowMarginTop;
    }
    if (rows === 3 && index > Math.ceil(iconsLength / 3 - 1)) {
      return icon.secondRowMarginTop;
    }
    return icon.marginTop;
  };

  const getSlotIcon = (index, icon, iconsLength) => {
    if (icon.firstRowLabel) {
      return slotRegularTop;
    }
    if (icon.secondRowLabel) {
      return slotBigDown;
    }
    if (icon.large) {
      return slotBigLeft;
    }
    if (index === icon.differentItems?.slotRegularRight?.index) {
      return slotRegularRight;
    }
    if (index === icon.differentItems?.slotBigLeft?.index) {
      return slotBigLeft;
    }
    if (index === icon.differentItems?.slotBigRight?.index) {
      return slotBigRight;
    }
    return slotRegularDiagonal;
  };

  const getImgClassnames = (icon, index) => {
    let className = "";
    if (!!icon.differentItems?.slotBigRight?.index) {
      className = `${className} biggerItems`;
    }
    if (index === icon.differentItems?.slotBigBottom?.index) {
      className = `${className} slotBigBottomSize`;
    }
    if (
      index === icon.differentItems?.slotBigRight?.index ||
      index === icon.differentItems?.slotRegularRight?.index
    ) {
      className = `${className} imgMarginLeftLess`;
    }
    if (
      index === icon.differentItems?.slotRegularLeft?.index ||
      index === icon.differentItems?.slotBigLeft?.index
    ) {
      className = `${className} imgMarginRightLess`;
    }
    if (index === 0 && icon.firstRowLabel) {
      className = `${className} slotRegularTopSize`;
    }
    if (icon.firstRowLabel) {
      className = `${className} firstRowLabel`;
    }
    return className;
  };

  return iconsList.map((icon, index) => {
    const imgStyle = {};

    if (icon.rows === 1) {
      imgStyle.width = "70px";
    }

    if (
      index === icon.differentItems?.slotBigLeft?.index ||
      index === icon.differentItems?.slotBigRight?.index
    ) {
      imgStyle.width = "85px";
      imgStyle.height = "85px";
    }

    return (
      <div
        id={`cell-${index}`}
        key={index}
        ref={cardRef}
        style={{
          marginTop: getMarginTop(icon.rows, index, icon, iconsList.length),
          marginLeft: getMarginLeft(icon.rows, index, icon, iconsList.length),
        }}
        className={`${step === 4 && "opacityCell"} ${
          icon.transparentIcons.find((item) => item === index + 1)
            ? "transparentCard"
            : null
        } ${selectedIconIndex === index ? "active" : ""} ${
          icon.largeHeight !== undefined ? "largeHeight" : ""
        }  ${icon.firstRowLabel === true ? "firstRowLabel" : ""} ${
          icon.secondRowLabel === true ? "secondRowLabel" : ""
        }`}
        onClick={() => handleIconClick(index, icon)} // Move onClick to the wrapping div
      >
        {icon.secondRowLabel !== true ? (
          <p
            className="icons-text"
            style={{ maxWidth: "86px", overflow: "hidden", width: "100%" }}
          >
            {icon.name === -1 ? "" : icon.name}
          </p>
        ) : null}
        <img
          style={imgStyle}
          className={`icon ${getImgClassnames(icon, index)} `}
          src={getSlotIcon(index, icon, iconsList.length)}
          alt={`Icon ${index}`}
        />
        {icon.secondRowLabel === true ? (
          <p
            className="icons-text icons-text-bottom"
            style={{ maxWidth: "86px", overflow: "hidden" }}
          >
            {icon.name === -1 ? "" : icon.name}
          </p>
        ) : null}
      </div>
    );
  });
};

export default PlateIconsList;
