import React, { useState, useEffect } from "react";
import optionOne from "../images/options/option1.svg";
import optionTwo from "../images/options/option2.svg";
import optionThree from "../images/options/option3.svg";
import optionFour from "../images/options/option4.svg";
import optionFive from "../images/options/option5.svg";
import optionSix from "../images/options/option6.svg";
import templates from "../templates.json";

const Selector = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [rows, setCountRows] = useState(null);


  useEffect(() => {
    setCountRows(props.rows);
    setSelectedImage(props.template);
  });

  function handleSelectImage(option) {
    switch (option) {
      case 1:
        setSelectedImage(optionOne);
        break;
      case 2:
        setSelectedImage(optionTwo);
        break;
      case 3:
        setSelectedImage(optionThree);
        break;
      case 4:
        setSelectedImage(optionFour);
        break;
      case 5:
        setSelectedImage(optionFive);
        break;
      case 6:
        setSelectedImage(optionSix);
        break;
    }
    props.setTemplateData(option);
  }

  /* 29.05 NEW TEMPLATE SYSTEM */

  const selectTemplateByID = (id, index) => {
    props.setActiveTemplate(id);
    //record to localstorage
    window.localStorage.setItem("activeTemplate", id);

    handleSelectImage(index);
    props.setStep(3)
  };

  return (
    <section>
      <div className="main-container">
        <p className="main-container-second-label">Now for the fun part—picking the layout for your ports. You get to call the shots on how complex or straightforward you want it.</p>
      </div>

      {(() => {
        switch (rows) {
          case 1:
            return (
              <div className="outer-container">
                {templates
                  .filter((x) => x.rows === 1)
                  .map((template, index) => (
                    <div key={`${index}${template.id}`} className="templateSelectBlock" onClick={(e) => selectTemplateByID(template.id, index)}>
                      <img
                        className={`inner-template ${selectedImage === 1 ? "active" : ""
                          }`}
                        src={template.templateImage}
                          alt={`Icon ${template.id}`}
                      />
                      <div className="templateSelectName">
                        <p>{template.label}</p>
                      </div>
                    </div>
                  ))}
              </div>
            );
          case 2:
            return (
              <div className="outer-container">
                {templates
                  .filter((x) => x.rows === 2)
                  .map((template, index) => (
                    <div key={`${index}${template.id}`} className="templateSelectBlock" onClick={(e) => selectTemplateByID(template.id, index)}>
                      <img
                        className={`inner-template ${selectedImage === 1 ? "active" : ""
                          }`}
                        src={template.templateImage}
                        alt={`Icon ${template.id}`}
                      />
                      <div className="templateSelectName template-2">
                        <p>{template.label}</p>
                      </div>
                    </div>
                  ))}
              </div>
            );
          case 3:
            return (
              <div className="outer-container">
                {templates
                  .filter((x) => x.rows === 3)
                  .map((template, index) => (
                    <div key={`${index}${template.id}`} className="templateSelectBlock" onClick={(e) => selectTemplateByID(template.id, index)}>
                      <img
                        className={`inner-template ${selectedImage === 1 ? "active" : ""
                          }`}
                        src={template.templateImage}
                        alt={`Icon ${template.id}`}
                      />
                      <div className="templateSelectName template-3">
                        <p>{template.label}</p>
                      </div>
                    </div>
                  ))}
              </div>
            );
          default:
            return null;
        }
      })()}
    </section>
  );
};

export default Selector;
