
import inputRightArrow from "../images/options/inputRightArrow.svg";
import inputLeftArrow from "../images/options/inputLeftArrow.svg";

const PlateTopControlBlock = ({step, handleIconClick, selectedIconIndex, inputValue, handleInputChange }) => {
    return(
        <div
          className="input-wrapper hidden"
          style={{ opacity: step === 3 ? 1 : 0 }}
        >
          <div className="arrow-wrapper">
            <div
              className="arrow"
              onClick={() => {
                handleIconClick(selectedIconIndex - 1)
              }}
            >
              <img src={inputLeftArrow} alt="arrowLeft" />
            </div>
            <input
              type="text"
              id="inputID"
              placeholder="Enter data"
              value={inputValue}
              onChange={handleInputChange}
            />
            <div
              className="arrow"
              onClick={() => {
                handleIconClick(selectedIconIndex + 1)
              }}
            >
              <img src={inputRightArrow} alt="arrowRight" />
            </div>
          </div>
        </div>
    )
}

export default PlateTopControlBlock
