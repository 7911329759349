import React, { useState, useEffect } from "react";
const SelectRows = (props) => {
  const [selected, setSelected] = useState(null);
  const isBigScreen = window.innerWidth > 768;

  useEffect(() => {
    setSelected(props.rowCount);
  });

  const handleContainerClick = (index) => {
    setSelected(index);
    props.setRows(index);
  };

  const getRowStyle = () => {
    if (!isBigScreen) {
      return {
        alignItems: "center",
        flexDirection: "column",
      };
    } else {
      return {
        alignItems: "center",
      };
    }
  };

  return (
    <section className="main-section-container">
      <div className="main-container">
        <p className="main-container-second-label">
          Kickstart your custom panel by choosing the number of rows you need.{" "}
        </p>
      </div>

      <div
        className="outer-container"
        style={{ paddingRight: isBigScreen ? "35px" : "0" }}
      >
        <div className="row-type-container" style={{ ...{ display: "flex" }, ...getRowStyle() }}>
          <p
            style={{marginRight: isBigScreen ? "10px" : "0" }}
          >
            1U
          </p>
          <div
            className={`inner-container ${selected === 1 ? "selected" : ""}`}
            onClick={() => handleContainerClick(1)}
          >
            <div className="inner-inner-container"></div>
          </div>
        </div>
        <div className="row-type-container" style={{ ...{ display: "flex" }, ...getRowStyle() }}>
          <p
            style={{marginRight: isBigScreen ? "10px" : "0" }}
          >
            2U
          </p>
          <div
            className={`inner-container ${selected === 2 ? "selected" : ""}`}
            onClick={() => handleContainerClick(2)}
          >
            <div className="inner-inner-container-2"></div>
            <div className="inner-inner-container-2"></div>
          </div>
        </div>
        <div className="row-type-container" style={{ ...{ display: "flex" }, ...getRowStyle() }}>
          <p
            style={{marginRight: isBigScreen ? "10px" : "0" }}
          >
            3U
          </p>
          <div
            className={`inner-container ${selected === 3 ? "selected" : ""}`}
            onClick={() => handleContainerClick(3)}
          >
            <div className="inner-inner-container-2"></div>
            <div className="inner-inner-container-2"></div>
            <div className="inner-inner-container-2"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelectRows;
