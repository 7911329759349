import React, { useState, useEffect, useRef } from "react";
import slotRegularDiagonal from "../images/slotRegularDiagonal.svg";
import slotBigLeft from "../images/slotBigLeft.svg";
import circle_small from "../images/options/circle_small.svg";
import PopupRow from "../components/PopupRow";
import CartPopup from "../components/CartPopup";
import FullLogo from "../images/full-logo.svg";
import UploadImage from "../images/upload-image.svg";
import templates from "../templates.json";
import logo from "../images/logo.svg";
import PlateTopControlBlock from "../components/PlateTopControlBlock";
import PlateIconsList from "../components/PlateIconsList";

const EditRow = ({
  update,
  rows,
  chooseImage,
  setLogotype,
  setCurrentTemplateData,
  step,
  activeTab,
  deleteLogo,
  setStep,
  setActiveTemplate,
  logotype,
  leftLogo,
  centerLogo,
  rightLogo,
  setLeftLogo,
  setCenterLogo,
  setRightLogo,
  data: propsData,
  activeTemplate: propsActiveTemplate,
  iconsList,
  setIconsList,
  pricesInfo,
  setInputValue,
  inputValue,
  deleteLeftLogo,
  deleteCenterLogo,
  deleteRightLogo,
}) => {
  const [leftImagePopup, setLeftImagePopup] = useState(false);
  const [rightImagePopup, setRightImagePopup] = useState(false);
  const [centerImagePopup, setCenterImagePopup] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);

  const [image, setImage] = useState(null);

  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState("auto");
  const [windowWidth, setWindowWidth] = useState("auto");

  const [activeRow] = useState(0);

  const [icons, setIcons] = useState();

  /* 29.05 NEW TEMPLATE SYSTEM */

  const [templateData, setTemplateData] = useState([]);
  const [data, setData] = useState([]); //новий основний массив з даними
  const dragRef = useRef(null);
  const ref = useRef(null);

  const cardRef = useRef(null);
  const scrollTemplateRef = useRef(null);

  const [activeShift, setActiveShift] = useState(-containerWidth / 2);
  const [initialShift, setInitialShift] = useState(0);

  const [containerHeight, setContainerHeight] = useState(131.3);

  const isDraggable = window.innerWidth > 768; // Перевіряємо ширину екрану

  const [position, setPosition] = useState({ x: 175, y: 0 });

  const [activeIcon, setActiveIcon] = useState(null);

  const smallCircle = 200;
  const bigCircle = 250;

  /* CONTAINER WIDTH  */
  console.log(propsData);
  let orderInfo = {
    CustomizedPlatePrice: 211.29,
    CustomLogoPrice: 222.1,
    ShippingPrice: 25.4,
  };

  useEffect(() => {
    setWindowWidth(ref.current.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(ref.current.clientWidth);
  };

  /* END */

  const handleInputChange = (e) => {
    let text = e.target.value;

    if (text.length > 10) {
      text = text.slice(0, 10);
    }

    setInputValue(text);
    update({
      name: text,
      id: selectedIconIndex,
      row: activeRow + 1,
    });
  };

  const skipTransparentIcon = (index) => {
    const indexBiggerThanPrevious = index > selectedIconIndex;

    let currentIndex = index + 1;
    while (templateData.transparentIcons.includes(currentIndex)) {
      indexBiggerThanPrevious ? currentIndex++ : currentIndex--;
    }
    if (
      iconsList.length + 1 ===
      templateData.transparentIcons[templateData.transparentIcons.length - 1] +
        1
    ) {
      return;
    }
    const cell = document.getElementById(`cell-${currentIndex - 1}`);
    if (cell) {
      cell.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    setSelectedIconIndex(currentIndex - 1);
    setActiveIcon(currentIndex - 1);

    return;
  };

  const handleIconClick = (index) => {
    /* INPUT FOCUS */
    if (index > iconsList.length - 1 || index < iconsList[0].id - 1) return;

    if (templateData.transparentIcons?.includes(index + 1)) {
      return skipTransparentIcon(index);
    }

    const cell = document.getElementById(`cell-${index}`);
    if (cell) {
      cell.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    const input = document.getElementById("inputID");
    input.focus();

    setSelectedIconIndex(index);
    let labelText = iconsList[index]?.name;

    if (labelText === -1 || labelText === undefined) {
      setInputValue("");
    } else {
      setInputValue(labelText);
    }
    setActiveIcon(index);
  };

  useEffect(() => {
    let data = propsData;
    let active = data.find((x) => x.row === activeRow + 1);
    if (active) {
      setIcons(active.data);
    }
  }, [propsData]);

  useEffect(() => {
    if (rows === 2) {
      setContainerHeight(263.3);
    } else if (rows === 3) {
      setContainerHeight(396.8);
    }
  }, [rows]);

  const setLeftLogoFunc = (data) => {
    setLeftLogo(data);
    setLeftImagePopup(false);
  };

  const setRightLogoFunc = (data) => {
    setRightLogo(data);
    setRightImagePopup(false);
  };

  const setCenterLogoFunc = (data) => {
    setCenterLogo(data);
    setCenterImagePopup(false);
  };

  useEffect(() => {
    if (chooseImage === "left") {
    } else if (chooseImage === "right") {
    }
  }, [chooseImage]);

  useEffect(() => {
    let width = rows === 1 ? 82 : 71;

    if (cardRef.current) {
      let containerWidth =
        width * 2 + cardRef.current.offsetWidth * templateData.iconsPerRow;

      if (templateData.circle !== false) {
        templateData.circle === "small"
          ? (containerWidth += smallCircle)
          : (containerWidth += bigCircle);
      }

      setContainerWidth(containerWidth);
      setInitialShift(723);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (cardRef.current) {
      let index = selectedIconIndex;
      let maxCircleCount = templateData.iconsPerRow;

      if (index > maxCircleCount) {
        if (index < maxCircleCount * 2) {
          index = index - maxCircleCount;
        } else {
          index = index - maxCircleCount * 2;
        }
      } else {
        index = index;
      }

      if (index === maxCircleCount) {
        index = 0;
      }

      const width = cardRef.current.offsetWidth;
      let shift;

      if (
        selectedIconIndex > iconsList.length - 2 &&
        templateData.circle !== false
      ) {
        if (templateData.circle === "small") {
          shift =
            initialShift -
            (width * templateData.iconsPerRow - 1) -
            smallCircle / 2 +
            40;
        } else if (templateData.circle === "big") {
          shift =
            initialShift -
            (width * templateData.iconsPerRow - 1) -
            bigCircle / 2 +
            30;
        }

        setSelectedIconIndex(selectedIconIndex);
      } else {
        shift = initialShift - width * index;
      }
      setActiveShift(shift);
    }
  }, [selectedIconIndex, windowWidth]);

  let activeTemplate =
    propsActiveTemplate === undefined || propsActiveTemplate === null
      ? Number(window.localStorage.getItem("activeTemplate"))
      : propsActiveTemplate;

  let currentTemplateData = templates.find((x) => x.id === activeTemplate);

  useEffect(() => {
    if (currentTemplateData !== undefined) {
      setCurrentTemplateData(currentTemplateData);
      setTemplateData(currentTemplateData);

      let iconsCount =
        currentTemplateData.rows * currentTemplateData.iconsPerRow;

      let icons = [];

      const savedData = JSON.parse(window.localStorage.getItem("orderData"));

      for (let i = 1; i <= iconsCount; i++) {
        const row = Math.ceil(i / currentTemplateData.iconsPerRow);
        if (i === 1 && currentTemplateData.firstSlotLarge === true) {
          icons.push({
            id: i,
            slot: `SLOT ${i}`,
            name:
              savedData.data.length !== 0
                ? savedData.data[0].data[i - 1]?.name
                : -1,
            row: row,
            large: true,
            marginTop: currentTemplateData.marginTop,
            secondRowMarginTop: currentTemplateData.secondRowMarginTop,
            firstMarginLeft: currentTemplateData.firstMarginLeft,
            marginLeft: currentTemplateData.marginLeft,
            rows: currentTemplateData.rows,
            differentItems: currentTemplateData?.differentItems,
            transparentIcons: currentTemplateData.transparentIcons,
            largeHeight: currentTemplateData?.largeHeight,
          });
        } else {
          icons.push({
            id: i,
            slot: `SLOT ${i}`,
            name:
              savedData.data.length !== 0
                ? savedData.data[0].data[i - 1]?.name
                : -1,
            row: row,
            marginTop: currentTemplateData.marginTop,
            secondRowMarginTop: currentTemplateData.secondRowMarginTop,
            firstMarginLeft: currentTemplateData.firstMarginLeft,
            marginLeft: currentTemplateData.marginLeft,
            rows: currentTemplateData.rows,
            differentItems: currentTemplateData?.differentItems,
            transparentIcons: currentTemplateData.transparentIcons,
            largeHeight: currentTemplateData?.largeHeight,
          });
        }
      }

      if (currentTemplateData.circle !== false) {
        icons.push({
          id: icons.length + 1,
          slot: `Circle${icons.length + 1}`,
          size: currentTemplateData.circle,
          name: -1,
          row: "-",
        });
      }

      if (currentTemplateData.firstLabelDiff === true) {
        icons[0].firstRowLabel = true;
        icons[currentTemplateData.iconsPerRow].secondRowLabel = true;
      }

      /* COOKIE FIX */

      if (propsData.length) {
        let data = [...propsData[0]?.data];
        if (data !== null || data !== undefined) {
          if (data.lenght === icons.length) {
            data.forEach((item, index) => {
              icons[index].name = item?.name;
            });
          }
        }
      }
      setIconsList(icons);
    }
  }, [inputValue, windowWidth, currentTemplateData]);

  useEffect(() => {
    let res = [...iconsList];
    let index = res.findIndex((item) => item.id === selectedIconIndex + 1);

    if (res[index] !== undefined) {
      res[index].name = inputValue;

      setIconsList(res);
      setData(res);
    }
  }, [inputValue]);

  useEffect(() => {
    if (step === 5) {
      setBurgerOpen(true);
    }
    if (step === 3 || step === 4 || step === 5) {
      handleResize();
    }
  }, [step]);

  useEffect(() => {
    setActiveIcon(selectedIconIndex);

    let input = document.getElementById("inputID");
    input.focus();
  }, [selectedIconIndex]);

  useEffect(() => {
    if (step !== 3) {
      setSelectedIconIndex("");
      setActiveIcon(null);
    }
    if (!isDraggable && (step === 3 || step === 4)) {
      ref.current.scrollTo(0, 0);
    }
  }, [activeTab]);

  useEffect(() => {
    if (image !== null) {
      setImage(null);
    }
  }, [deleteLogo]);

  const closeBurger = () => {
    setBurgerOpen(false);
    setStep(4);
  };

  const getStepTitle = () => {
    if (step === 4) {
      return "Add your Logo";
    }
    if (step === 5) {
      return "Confirm your selections";
    }
    return "Add your Labels";
  };

  const getStepSubtitle = () => {
    if (step === 3) {
      return "Get your label game on!  Time to label those ports. Name them, or number them! Your choice.";
    }
    return "Take a second to admire your creation. Everything look good? If it's all systems go, let's lock it in!";
  };

  const getLeftLogoBlock = () => {
    if (leftLogo) {
      return (
        <img src={leftLogo} className="logoTemplate-left" alt="Uploaded-icon" />
      );
    } else {
      return (
        <>
          {step === 3 ? (
            <img
              className=""
              style={{ width: "40px", height: "40px" }}
              src={logo}
              alt="step-3-logo"
            />
          ) : null}
          {step === 4 && (
            <div
              style={{
                backgroundColor: "#d9d9d9",
                borderRadius: "5px",
                width: "45px",
                height: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="plus-block">
                <p>+</p>
              </div>
              <img
                className="icon-logo-placeholder"
                style={{ width: "20px", height: "20px" }}
                src={UploadImage}
                alt="step-4-logo"
              />
            </div>
          )}
        </>
      );
    }
  };

  const getRightLogoBlock = () => {
    if (rightLogo) {
      return (
        <img
          src={rightLogo}
          className="logoTemplate-right"
          alt="Uploaded-icon"
        />
      );
    } else {
      return (
        <>
          {step === 3 ? (
            <img
              className=""
              style={{ width: "40px", height: "40px" }}
              src={logo}
              alt="step-3-logo"
            />
          ) : null}
          {step === 4 && (
            <div
              style={{
                backgroundColor: "#d9d9d9",
                borderRadius: "5px",
                width: "45px",
                height: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="plus-block2">
                <p>+</p>
              </div>
              <img
                className="icon-logo-placeholder"
                style={{ width: "20px", height: "20px" }}
                src={UploadImage}
                alt="step-4-logo"
              />
            </div>
          )}
        </>
      );
    }
  };
  const getCenterLogoBlock = () => {
    if (centerLogo) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={centerLogo}
            alt="Uploaded center logo"
            style={{
              height: templateData.centerLogoHeight,
              maxWidth: templateData.centerLogoWidth,
            }}
          />
        </div>
      );
    } else if (!centerLogo && step === 3) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={FullLogo}
            alt="full-logo"
            style={{
              height: templateData.centerLogoHeight,
              maxWidth: templateData.centerLogoWidth,
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "#d9d9d9",
            width: "100%",
            height: "100%",
            borderRadius: "14px",
            cursor: "pointer",
          }}
        >
          <img
            src={UploadImage}
            alt="Upload Icon"
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      );
    }
  };

  const getEarsStyle = () => {
    if (rows === 1) {
      return {
        paddingTop: "6px",
        paddingBottom: "6px",
        paddingLeft: "4px",
        paddingRight: "0px",
      };
    }
    return {
      paddingTop: "20px",
      paddingBottom: "20px",
      paddingLeft: "15px",
    };
  };

  return (
    <div className="edit-row">
      <div className="main-container">
        <div className="containerTop">
          <div>
            <p className="main-lable-text" id="thirdLabel">
              {getStepTitle()}
            </p>
            <p className="main-container-second-label" id="thirdLabel">
              {getStepSubtitle()}
            </p>
          </div>
        </div>
      </div>
      {selectedIconIndex !== null ? (
        <PlateTopControlBlock
          selectedIconIndex={selectedIconIndex}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          handleIconClick={handleIconClick}
          step={step}
        />
      ) : (
        <div className="inputContainer"></div>
      )}
      <div
        className="container template-row"
        ref={ref}
        style={{ height: containerHeight }}
      >
        {true ? (
          <div
            ref={dragRef}
            className="icons"
            style={{
              width:
                rows === 1
                  ? "1448px"
                  : templateData.templateWidth || containerWidth,
              margin:
                containerWidth || templateData.templateWidth < windowWidth
                  ? "0 auto"
                  : "0",
              minWidth: templateData.iconsMinWidth,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                ...{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  paddingRight: "0px",
                },
                ...getEarsStyle(),
              }}
            >
              <div
                style={{
                  backgroundColor: "#000000",
                  width: "30px",
                  height: "21px",
                  borderRadius: "16px",
                }}
              />
              <div
                className="icon-left-wrapper"
                onClick={() => {
                  step === 4 && setLeftImagePopup(true);
                }}
              >
                {getLeftLogoBlock()}
              </div>
              <div
                style={{
                  backgroundColor: "#000000",
                  width: "30px",
                  height: "21px",
                  borderRadius: "16px",
                }}
              />
            </div>
            {templateData.haveCenterLogo && (
              <div
                onClick={() => {
                  step === 4 && setCenterImagePopup(true);
                }}
                style={{
                  position: "absolute",
                  width: templateData.centerLogoWidth,
                  height: templateData.centerLogoHeight,
                  left: "50%",
                  top: "30px",
                  transform: "translateX(-50%)",
                }}
              >
                {getCenterLogoBlock()}
              </div>
            )}
            <div
              className={`icons-container ${
                step === 4 || step === 5 ? "icons-transparent" : ""
              }`}
              ref={dragRef}
              style={{ gap: templateData.gap }}
            >
              {iconsList !== undefined &&
                iconsList.length > 0 &&
                (templateData.circle ? (
                  <div
                    className={`circleWrapper
                        ${
                          templateData.rows === 2
                            ? "twoRows"
                            : templateData.rows === 3
                            ? "threeRows"
                            : null
                        }`}
                  >
                    <div className="circleWrapperList">
                      {iconsList.map((icon, index) =>
                        icon.slot.indexOf("SLOT") !== -1 ? (
                          <div
                            key={index}
                            ref={cardRef}
                            className={`${
                              templateData.transparentIcons.find(
                                (item) => item === index + 1
                              )
                                ? "transparentCard"
                                : ""
                            } ${
                              templateData.largeHeight !== undefined
                                ? "largeHeight"
                                : ""
                            } ${activeIcon === index ? "active" : ""}
                          ${icon?.large === true ? "largeLabel" : ""}`}
                            onClick={() => handleIconClick(index)} // Move onClick to the wrapping div
                          >
                            <p
                              className="icons-text"
                              style={{ maxWidth: "90px", overflow: "hidden" }}
                            >
                              {icon?.name === -1 ? "" : icon?.name}
                            </p>
                            <img
                              className="icon"
                              src={
                                icon.large === true
                                  ? slotBigLeft
                                  : slotRegularDiagonal
                              }
                              alt={`Icon ${index}`}
                            />
                          </div>
                        ) : null
                      )}
                    </div>
                    <div className={`circle ${templateData.circle}`}>
                      <p
                        className="icons-text"
                        style={{ maxWidth: "90px", overflow: "hidden" }}
                      >
                        {iconsList[iconsList.length - 1]?.name === -1
                          ? ""
                          : iconsList[iconsList.length - 1]?.name}
                      </p>
                      <img
                        src={circle_small}
                        alt=""
                        onClick={() => handleIconClick(iconsList.length - 1)}
                      />
                    </div>
                  </div>
                ) : (
                  <PlateIconsList
                    step={step}
                    cardRef={cardRef}
                    handleIconClick={handleIconClick}
                    iconsList={iconsList}
                    selectedIconIndex={selectedIconIndex}
                  />
                ))}
            </div>
            <div
              style={{
                ...{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: rows === 1 ? "center" : "unset",
                  height: "100%",
                  paddingLeft: "0",
                  paddingRight: "10px",
                },
                ...getEarsStyle(),
              }}
            >
              <div
                style={{
                  backgroundColor: "#000000",
                  width: "30px",
                  height: "21px",
                  borderRadius: "16px",
                }}
              />
              <div
                className={`${rows === 1 ? "" : "icon-left-wrapper"}`}
                onClick={() => {
                  step === 4 && setRightImagePopup(true);
                }}
              >
                {getRightLogoBlock()}
              </div>
              <div
                style={{
                  backgroundColor: "#000000",
                  width: "30px",
                  height: "21px",
                  borderRadius: "16px",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="labelsScroll" ref={scrollTemplateRef}>
            <div
              className="icons"
              style={{
                transform: `translateX(${
                  selectedIconIndex === 0 ? initialShift : activeShift
                }px)`,
                width: containerWidth,
                minWidth: templateData.iconsMinWidth,
              }}
            >
              <div
                style={{
                  ...{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    paddingLeft: "15px",
                    paddingRight: "0px",
                  },
                  ...getEarsStyle(),
                }}
              >
                <div
                  style={{
                    backgroundColor: "#000000",
                    width: "30px",
                    height: "21px",
                    borderRadius: "16px",
                  }}
                />
                <div
                  className="icon-left-wrapper"
                  onClick={() => {
                    step === 4 && setLeftImagePopup(true);
                  }}
                >
                  {getLeftLogoBlock()}
                </div>
                <div
                  style={{
                    backgroundColor: "#000000",
                    width: "30px",
                    height: "21px",
                    borderRadius: "16px",
                  }}
                />
              </div>
              <div
                className={`icons-container ${
                  step === 4 || step === 5 ? "icons-transparent" : ""
                }`}
                style={{ gap: templateData.gap }}
              >
                {templateData.haveCenterLogo && (step === 4 || step === 5) && (
                  <div
                    onClick={() => {
                      step === 4 && setCenterImagePopup(true);
                    }}
                    className="center-logo-container"
                    style={{
                      position: "absolute",
                      width: templateData.centerLogoWidth,
                      height: templateData.centerLogoHeight,
                      left: "50%",
                      top: "30px",
                      transform: "translateX(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {getCenterLogoBlock()}
                  </div>
                )}
                {iconsList !== undefined &&
                  iconsList.length > 0 &&
                  (templateData.circle ? (
                    <div
                      className={`circleWrapper ${
                        templateData.rows === 2
                          ? "twoRows"
                          : templateData.rows === 3
                          ? "threeRows"
                          : null
                      }`}
                    >
                      <div className="circleWrapperList">
                        {iconsList.map((icon, index) =>
                          icon.slot.indexOf("SLOT") !== -1 ? (
                            <div
                              key={index}
                              ref={cardRef}
                              className={`${
                                templateData.transparentIcons.find(
                                  (item) => item === index + 1
                                )
                                  ? "transparentCard"
                                  : ""
                              } ${
                                templateData.largeHeight !== undefined
                                  ? "largeHeight"
                                  : ""
                              } ${activeIcon === index ? "active" : ""}
                        ${icon.large === true ? "largeLabel" : ""}`}
                              onClick={() => handleIconClick(index)} // Move onClick to the wrapping div
                            >
                              <p className="icons-text">
                                {icon?.name === -1 ? "" : icon?.name}
                              </p>
                              <img
                                className="icon"
                                src={
                                  icon.large === true
                                    ? slotBigLeft
                                    : slotRegularDiagonal
                                }
                                alt={`Icon ${index}`}
                              />
                            </div>
                          ) : null
                        )}
                      </div>
                      <div className={`circle ${templateData.circle}`}>
                        <p className="icons-text">
                          {iconsList[iconsList.length - 1]?.name === -1
                            ? ""
                            : iconsList[iconsList.length - 1]?.name}
                        </p>
                        <img
                          src={circle_small}
                          alt=""
                          onClick={() => handleIconClick(iconsList.length - 1)}
                        />
                      </div>
                    </div>
                  ) : (
                    <PlateIconsList
                      cardRef={cardRef}
                      handleIconClick={handleIconClick}
                      iconsList={iconsList}
                      selectedIconIndex={selectedIconIndex}
                    />
                  ))}
              </div>
              <div
                style={{
                  ...{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    paddingLeft: "0",
                    paddingRight: "10px",
                  },
                  ...getEarsStyle(),
                }}
              >
                <div
                  style={{
                    backgroundColor: "#000000",
                    width: "30px",
                    height: "21px",
                    borderRadius: "16px",
                  }}
                />
                <div
                  className="icon-left-wrapper"
                  onClick={() => {
                    step === 4 && setRightImagePopup(true);
                  }}
                >
                  {getRightLogoBlock()}
                </div>
                <div
                  style={{
                    backgroundColor: "#000000",
                    width: "30px",
                    height: "21px",
                    borderRadius: "16px",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {leftImagePopup && (
        <PopupRow
          setPopupOpen={setLeftImagePopup}
          setLogo={setLeftLogoFunc}
          setLogotype={setLeftLogo}
          logo={"Left"}
        />
      )}
      {rightImagePopup && (
        <PopupRow
          logo={"Right"}
          setPopupOpen={setRightImagePopup}
          setLogo={setRightLogoFunc}
          setLogotype={setRightLogo}
        />
      )}
      {centerImagePopup && (
        <PopupRow
          templateData={templateData}
          logo={"Center"}
          setPopupOpen={setCenterImagePopup}
          setLogo={setCenterLogoFunc}
          setLogotype={setCenterLogo}
        />
      )}
      {burgerOpen && (
        <CartPopup
          burgerOpen={burgerOpen}
          logotype={logotype}
          setLogo={setImage}
          setBurgerOpen={setBurgerOpen}
          closeBurger={closeBurger}
          setDeleteLogo={() => setLogotype(null)}
          orderInfo={orderInfo}
          data={propsData}
          templateData={templateData}
          pricesInfo={pricesInfo}
          leftLogo={leftLogo}
          centerLogo={centerLogo}
          rightLogo={rightLogo}
          deleteLeftLogo={deleteLeftLogo}
          deleteCenterLogo={deleteCenterLogo}
          deleteRightLogo={deleteRightLogo}
        />
      )}
    </div>
  );
};

export default EditRow;
