import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    console.log("1.19");
  }, []);
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="" element={<Main />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
