import React, { useState, useEffect } from "react";
import logo from "../images/logo.svg";
import rightArrow from "../images/right-arrow.svg";
import checkmark from "../images/checkMark.svg";
import SelectRows from "../pages/SelectRows";
import SelectTemplate from "../pages/SelectTemplate";
import EditRow from "../pages/EditRow";
import CartPopup from "../components/CartPopup";
import templates from "../templates.json";
import axios from "axios";
import { useTotalPrice } from "../hooks/useTotalPrice";

const Main = () => {
  const [isDarkMode] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [burgerOpen, setBurgerOpen] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [rows, setCountRows] = useState(null);
  const [template, setTemplate] = useState(null);
  const [templateData, setCurrentTemplateData] = useState(null);
  const [priceList, setPriceList] = useState(null);

  const [data, setData] = useState([]);
  const [pricesInfo, setPricesInfo] = useState({});

  const [isLoadData, setLoadDataStatus] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);

  const [logotype, setLogotype] = useState(null);
  const [leftLogo, setLeftLogo] = useState(null);
  const [centerLogo, setCenterLogo] = useState(null);
  const [rightLogo, setRightLogo] = useState(null);
  const [deleteLogo] = useState(false);
  const [inputValue, setInputValue] = useState("");

  let currentTemplateData = templates.find((x) => x.id === activeTemplate);
  const getPriceList = async () => {
    try {
      const response = await axios.get(
        "https://orders.savantplayback.com/api/v1/getProductsPrices"
      );
      setPriceList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const setLogotype = (image, position) => {
  //   if (position === LEFT_POSITION) {
  //     setLeftLogo(image);
  //   } else if (position === CENTER_POSITION) {
  //     setCenterLogo(image);
  //   } else if (position === RIGHT_POSITION) {
  //     setRightLogo(image);
  //   }
  // }


  const getTotalPrice = useTotalPrice({
    templateData: currentTemplateData,
    priceList: priceList,
    data: iconsList,
    leftLogo: leftLogo,
    rightLogo: rightLogo,
    centerLogo: centerLogo,
  });

  useEffect(() => {
    getPriceList();
  }, []);

  useEffect(() => {
    const pricesResult = getTotalPrice();
    setPricesInfo(pricesResult);
  }, [activeTab, iconsList, leftLogo, centerLogo, rightLogo]);

  const tabsList = [
    { tabIndex: 1, title: "Rows" },
    { tabIndex: 2, title: "Layout" },
    { tabIndex: 3, title: "Labels" },
    { tabIndex: 4, title: "Logo" },
  ];

  let orderInfo = {
    CustomizedPlatePrice: 211.29,
    CustomLogoPrice: 222.1,
    ShippingPrice: 25.4,
  };

  useEffect(() => {
    if (isLoadData) {
      saveToLocalStorage();
    }
  }, [rows, template, data, activeTab]);

  const saveToLocalStorage = () => {
    localStorage.setItem(
      "orderData",
      JSON.stringify({
        rows: rows,
        template: template,
        data: data,
        activeTab: activeTab,
      })
    );
  };

  const readFromLocalStorage = () => {
    const localStorageData = localStorage.getItem("orderData");
    if (localStorageData) {
      let storedData = JSON.parse(localStorageData);
      if (storedData.rows != null) {
        setCountRows(storedData.rows);
      }
      if (storedData.template != null) {
        setTemplate(storedData.template);
      }
      if (storedData.data != null) {
        setData(storedData.data);
      }
      if (storedData.activeTab != null) {
        setActiveTab(storedData.activeTab);
      }
    }
    setLoadDataStatus(true);
  };

  useEffect(() => {
    readFromLocalStorage();
  }, [activeTab]);

  const setRows = (index) => {
    setCountRows(index);
    if (template != null && index != null) {
      generateData(index, template);
    }
    handleTabClick(2, true);
  };

  const setTemplateData = (index) => {
    setTemplate(index);
    if (index != null && rows != null && index !== template) {
      setInputValue("");
      generateData(rows, index);
    }
    handleTabClick(3, true);
  };

  const updateData = (jsonData) => {
    let activeData = data;
    let row = activeData.find((x) => x.row === jsonData.row);
    if (row) {
      let slotId = `SLOT ${jsonData.id}`;
      let slot = row.data?.find((x) => x.slot === `SLOT ${jsonData.id + 1}`);
      if (slot) {
        slot.name = jsonData.name;
        setData(activeData);
        saveToLocalStorage(activeData);
      }
    }
  };

  const generateData = (rows, template) => {
    const jsonData = [];
    var countSlots = getSlotsCount(rows);
    for (let i = 1; i <= rows; i++) {
      const slots = [];

      for (let j = 1; j <= countSlots; j++) {
        var slot = `SLOT ${j}`;
        slots.push({ slot: slot, name: -1 });
      }

      jsonData.push({
        row: i,
        template,
        data: slots,
      });
    }
    setData(jsonData);
  };

  const getSlotsCount = (rows) => {
    if (rows === 1) {
      return 16;
    }
    if (rows === 2) {
      return 32;
    }
    if (rows === 3) {
      return 48;
    }
  };

  const handleTabClick = (tabIndex, isDirect = false) => {
    if (tabIndex >= 1 && tabIndex <= 5) {
      switch (tabIndex) {
        case 2:
          if (!rows && !isDirect) {
            return;
          }
          break;
        case 3:
          if (!template) {
            return;
          }
          break;
        case 4:
          //setBurgerOpen(true)
          break;
        case 5:
          break;
      }
      setActiveTab(tabIndex);
    }
  };

  const closeBurger = () => {
    setBurgerOpen(false);
    setActiveTab(3);
  };

  // const testData = () => {
  //   var currentData = data[0];
  //   currentData.template = templateData;

  //   let filteredData;

  //   if (templateData.transparentIcons.length > 0) {
  //     filteredData = currentData.data.filter((item) => item.name !== -1);
  //   } else {
  //     filteredData = currentData.data;
  //   }

  //   filteredData = filteredData.map((item, index) => ({
  //     ...item,
  //     slot: "SLOT " + (index + 1),
  //     name: typeof item.name === "string" ? item.name.toUpperCase() : item.name,
  //   }));

  //   currentData.data = filteredData;
  //   axios
  //     .post("https://create.savantplayback.com/test", currentData)
  //     .then((response) => {})
  //     .catch((error) => {
  //       console.error(error);
  //       // Handle error
  //     });
  // };

  useEffect(() => {
    if (activeTab === 1 || activeTab === 2) {
      setActiveTemplate(null);
      setIconsList([]);
      setInputValue("");
    }
    if (activeTab === 1) {
      setData([]);
      // setLogotype(null);
      setLeftLogo(null);
      setCenterLogo(null);
      setRightLogo(null);
    }
  }, [activeTab]);

  function changeActiveTab(tab) {
    setActiveTab(tab);
  }

  const getActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <div className="main-lable">
              <p className="main-lable-text">
                Select the number of rows
              </p>
            </div>
            <SelectRows rowCount={rows} setRows={setRows} />
          </>
        );
      case 2:
        return (
          <div className="second-page-lable">
            <div className="main-lable">
              <p className="main-lable-text">Choose your configuration</p>
            </div>
            <SelectTemplate
              template={template}
              rows={rows}
              setTemplateData={setTemplateData}
              setActiveTemplate={setActiveTemplate}
              setStep={setActiveTab}
              deleteLogo={deleteLogo}
            />
          </div>
        );
      case 3:
        return (
          <EditRow
            activeTemplate={activeTemplate}
            rows={rows}
            template={template}
            data={data}
            activeTab={activeTab}
            update={updateData}
            setCurrentTemplateData={setCurrentTemplateData}
            step={3}
            setStep={setActiveTab}
            deleteLogo={deleteLogo}
            logotype={logotype}
            setActiveTemplate={setActiveTemplate}
            iconsList={iconsList}
            setIconsList={setIconsList}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        );
      case 4:
        return (
          <EditRow
            rows={rows}
            template={template}
            data={data}
            activeTab={activeTab}
            update={updateData}
            chooseImage={"left"}
            setCurrentTemplateData={setCurrentTemplateData}
            step={4}
            setLogotype={setLogotype}
            logotype={logotype}
            setStep={setActiveTab}
            deleteLogo={deleteLogo}
            setActiveTemplate={setActiveTemplate}
            iconsList={iconsList}
            setIconsList={setIconsList}
            pricesInfo={pricesInfo}
            inputValue={inputValue}
            setInputValue={setInputValue}
            leftLogo={leftLogo}
            centerLogo={centerLogo}
            rightLogo={rightLogo}
            setLeftLogo={setLeftLogo}
            setCenterLogo={setCenterLogo}
            setRightLogo={setRightLogo}
          />
        );
      case 5:
        return (
          <EditRow
            rows={rows}
            deleteLogo={deleteLogo}
            template={template}
            data={data}
            activeTab={activeTab}
            update={updateData}
            chooseImage={"right"}
            setCurrentTemplateData={setCurrentTemplateData}
            step={5}
            leftLogo={leftLogo}
            centerLogo={centerLogo}
            rightLogo={rightLogo}
            logotype={logotype}
            setStep={setActiveTab}
            setBurgerOpen={setBurgerOpen}
            iconsList={iconsList}
            setIconsList={setIconsList}
            setLogotype={setLogotype}
            setLeftLogo={setLeftLogo}
            setCenterLogo={setCenterLogo}
            setRightLogo={setRightLogo}
            pricesInfo={pricesInfo}
            inputValue={inputValue}
            setInputValue={setInputValue}
            deleteLeftLogo={() => setLeftLogo(null)}
            deleteCenterLogo={() => setCenterLogo(null)}
            deleteRightLogo={() => setRightLogo(null)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={isDarkMode ? "dark" : "light"}>
      <div className="window">
        <div className="header">
          <img
            src={logo}
            alt="logo-app"
            className="logo-button"
            onClick={() => {
              setActiveTab(1);
              setActiveTemplate(null);
              window.localStorage.removeItem("cart");
              window.localStorage.removeItem("activeTemplate");
              window.localStorage.removeItem("step");
              window.localStorage.removeItem("pageNumber");
              window.localStorage.removeItem("orderData");
            }}
          />
        </div>
        <div className="tabs-container">
          <div className="tabs-wrap">
            {tabsList.map(({ tabIndex, title }, index) => (
              <React.Fragment key={index}>
                <div
                  onClick={(e) => changeActiveTab(tabIndex)}
                  className={`tab ${activeTab >= tabIndex ? "active" : ""}`}
                >
                  {activeTab <= tabIndex ? (
                    <div className="tabItemWr">
                      <div className="tabTextBox">
                        <p className="tabText">{tabIndex}</p>
                        <p className="tabText">{title}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="tab-check-box">
                    <img src={checkmark} alt="check" className="check-icon" />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {getActiveTabContent()}
        {burgerOpen && (
          <CartPopup
            burgerOpen={burgerOpen}
            setBurgerOpen={setBurgerOpen}
            orderInfo={orderInfo}
            closeBurger={closeBurger}
            setDeleteLogo={() => setLogotype(null)}
            deleteLogo={deleteLogo}
            data={data}
            logotype={logotype}
            templateData={templateData}
            pricesInfo={pricesInfo}
            leftLogo={leftLogo}
            centerLogo={centerLogo}
            rightLogo={rightLogo}
            deleteLeftLogo={() => setLeftLogo(null)}
            deleteCenterLogo={() => setCenterLogo(null)}
            deleteRightLogo={() => setRightLogo(null)}
          />
        )}
        <div className="bottom-control-wr">
          <div className="wrapper">
            <div className="center-text">
              <p>Total</p>
              <p className="total-price">${pricesInfo.totalPrice}</p>
            </div>
            <div className="container_button">
              <div
                className="right-container"
                onClick={() => handleTabClick(activeTab - 1)}
              >
                <button className="outer-button right-button previous_button">
                  <img src={rightArrow} alt="Right Arrow" />
                </button>
                <div className="next-step-text">Previous</div>
              </div>
              <div
                className="right-container"
                onClick={() => handleTabClick(activeTab + 1)}
              >
                <div className="next-step-text">Next</div>
                <button className="outer-button right-button">
                  <img src={rightArrow} alt="Right Arrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
