import "../App.css";
import React, { useEffect, useState } from "react";
import leftArrow from "../images/options/inputLeftArrow.svg";
import deleteBlock from "../images/delete.svg";
import rightArrow from "../images/external_add_to_cart.svg";
import LogoLabelOrderElement from "../images/logo-label-order-element.svg";
import PlateOrderElement from "../images/plate-order-element.svg";
import axios from "axios";
const FormData = require("form-data");

const CartPopup = ({
  data: propsData,
  templateData: propsTemplateData,
  orderInfo: propsOrderInfo,
  logotype,
  setDeleteLogo,
  burgerOpen,
  closeBurger,
  pricesInfo,
  leftLogo,
  centerLogo,
  rightLogo,
  deleteLeftLogo,
  deleteCenterLogo,
  deleteRightLogo,
}) => {

  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const testData = async () => {
    let currentData = JSON.parse(JSON.stringify(propsData[0]));
    if (currentData) {
      currentData.template = propsTemplateData;
    } else {
      console.error("currentData is undefined");
    }
    let filteredData;
    if (propsTemplateData?.transparentIcons.length) {
      filteredData = currentData.data.filter(
        (item) =>
          !propsTemplateData.transparentIcons.includes(+item.slot.split(" ")[1])
      );
    } else {
      filteredData = currentData.data;
    }
    console.log(filteredData, filteredData.length);
    console.log(propsTemplateData?.transparentIcons);

    currentData.data = filteredData.map((item, index) => ({
      ...item,
      slot: "SLOT " + (index + 1),
    }));
    console.log(currentData.data);
    currentData.amount = (
      orderInfo.CustomizedPlatePrice + orderInfo.CustomLogoPrice
    ).toFixed(2);

    const formData = new FormData();
    formData.append("data", JSON.stringify(currentData));
    formData.append("regularLeftLogo", leftLogo);
    formData.append("regularRightLogo", rightLogo);
    formData.append("largeLogo", centerLogo);

    try {
      setLoading(true);
      const response = await axios
        .post("https://orders.savantplayback.com/api/v1/checkout", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .finally(() => setLoading(false));

      window.open(response.data.cart_url, '_blank', 'noreferrer')
      //window.location.href = response.data.cart_url;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setOrderInfo(propsOrderInfo);
  });

  return (
    <div
      className={
        burgerOpen === true ? "BurgerPopup activeBurger" : "BurgerPopup"
      }
    >
      {!!orderInfo && (
        <div className="cart-popup-wr">
          <div className="BurgerPopup_list">
            <div className="cart-header">
              <button
                onClick={(event) => {
                  closeBurger();
                }}
                className="header-button left-button"
              >
                <div className="button-gradient-inner-bg">
                  <img src={leftArrow} alt="Left Arrow" />
                </div>
              </button>
              <h2>Order Summary</h2>
            </div>
            <div className="order_block">
              <img className="deleteBlock" alt="" />
              <div className="order_img">
                <img
                  src={PlateOrderElement}
                  width={58}
                  height={58}
                  alt="plate-order-icon"
                />
              </div>
              <div className="order_blockList">
                <p className="order_name">Base Plate</p>
                <p className="order_price">
                  Unit Price: <span>${pricesInfo.templatePrice}</span>
                </p>
              </div>
            </div>
            {!!leftLogo && (
              <div className="order_block">
                <img
                  className="deleteBlock"
                  onClick={() => deleteLeftLogo()}
                  src={deleteBlock}
                  alt=""
                />
                <div className="order_img">
                  <img
                    src={LogoLabelOrderElement}
                    width={58}
                    height={58}
                    alt="logo-labels-order-icon"
                  />
                </div>
                <div className="order_blockList">
                  <p className="order_name">Engraved Left Logo</p>
                  <p className="order_price">
                    Unit Price: <span>${pricesInfo.priceLeftLogo}</span>
                  </p>
                </div>
              </div>
            )}
            {!!centerLogo && (
              <div className="order_block">
                <img
                  className="deleteBlock"
                  onClick={() => deleteCenterLogo()}
                  src={deleteBlock}
                  alt=""
                />
                <div className="order_img">
                  <img
                    src={LogoLabelOrderElement}
                    width={58}
                    height={58}
                    alt="logo-labels-order-icon"
                  />
                </div>
                <div className="order_blockList">
                  <p className="order_name">Engraved Center Logo</p>
                  <p className="order_price">
                    Unit Price: <span>${pricesInfo.priceCenterLogo}</span>
                  </p>
                </div>
              </div>
            )}
            {!!rightLogo && (
              <div className="order_block">
                <img
                  className="deleteBlock"
                  onClick={() => deleteRightLogo()}
                  src={deleteBlock}
                  alt=""
                />
                <div className="order_img">
                  <img
                    src={LogoLabelOrderElement}
                    width={58}
                    height={58}
                    alt="logo-labels-order-icon"
                  />
                </div>
                <div className="order_blockList">
                  <p className="order_name">Engraved Right Logo</p>
                  <p className="order_price">
                    Unit Price: <span>${pricesInfo.priceRightLogo}</span>
                  </p>
                </div>
              </div>
            )}
            {!!pricesInfo?.filledCellsCount && (
              <div className="order_block">
                <div className="order_img">
                  <img
                    src={LogoLabelOrderElement}
                    width={58}
                    height={58}
                    alt="logo-labels-order-icon"
                  />
                </div>
                <div className="order_blockList">
                  <p className="order_name">{`Engraved Labels`}</p>
                  <p className="order_price">
                    Count: <span>{pricesInfo.filledCellsCount}</span>
                  </p>
                  <p className="order_price">
                    Unit Price: <span>${pricesInfo.slotPrice.toFixed(2)}</span>
                  </p>
                  <p className="order_price">
                    Subtotal:{" "}
                    <span>${pricesInfo.priceOfFilledCells.toFixed(2)}</span>
                  </p>
                </div>
              </div>
            )}

            <div className="subtotal">
              <p>Subtotal</p>
              <p>${pricesInfo.totalPrice}</p>
            </div>
            <div className="subtotal">
              <p>Shipping</p>
              <p>Calculated at checkout</p>
            </div>
            <hr />
            <div className="order_total">
              <p>Total</p>
              <p>${pricesInfo.totalPrice}</p>
            </div>
          </div>
          <div className="order_buttonСontainer">
            <div>
              <div className="order_button_wr">
                <button
                  disabled={loading}
                  onClick={(e) => {
                    testData();
                  }}
                  className=" right-button order_button button2"
                >
                  {loading ? (
                    <>
                      <svg>
                        <rect></rect>
                      </svg>
                      <p className="cart-checkout-text unselectable">
                        Generating your order, please standby...
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="cart-checkout-text unselectable">
                        Add to Cart
                      </p>
                      <img
                        className="unselectable"
                        src={rightArrow}
                        alt="Right Arrow"
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CartPopup;
