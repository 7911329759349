import "../App.css";
import logoSavant from "../images/logo.svg";
import leftArrow from "../images/left-arrow.svg";
import save from "../images/save.svg";
import upload from "../images/upload.svg";
import React, { useState, useRef } from "react";

const PopupRow = ({
  setLogo,
  setLogotype,
  setPopupOpen,
  logo,
  templateData = {},
}) => {
  const myInput = useRef(null);

  const clickElement = () => {
    myInput.current?.click();
  };

  const [checkbox, setCheckbox] = useState(false);
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const sendPhoto = () => {
    setLogo(image);
    setLogotype(image);
  };

  return (
    <div className="PopupRow">
      <div
        className="PopupRow_list"
        style={{ maxWidth: logo === "Center" ? `${+templateData.centerLogoWidth.slice(0, -2) + 40}px` : "360px" }}
      >
        <div className="header header_popup popup-without-center" style={{display: 'flex', alignItems: 'center' ,justifyContent: 'space-between'}} >
          <button
            onClick={(event) => {
              setPopupOpen(false);
            }}
            className="header-button left-button"
          >
            <img src={leftArrow} alt="Left Arrow" />
          </button>
          <img
            src={logoSavant}
            className="header-logo"
            alt="Logo"
          />
          <div style={{width: '56px'}} />
        </div>
        <div className="popup_lorem">
          <p className="popup_title">Add your {logo} logo</p>
          <p
          >
            Before you upload, here's the lowdown: Make sure your logos are
            proportionate to the containers they'll live in. Keep in mind all
            logos will be engraved by our fiber lasers and will be white logo
            markings. Please upload hi res SVG, PNG, or JPEG files only for the
            best results Vector files are recommended.
          </p>
        </div>
        <div
          className="plug"
          style={{
            height: logo === "Center" ? `${+templateData.centerLogoHeight.slice(0, -2) + 40}px` : "200px",
            backgroundColor: image ? "transparent" : "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => clickElement()}
        >
          <div className="popupUploadButton">
            {!image && (
              <button className="popup_upload">
                <img src={upload} alt="" />
                Upload Image
              </button>
            )}
          </div>
          {image && (
            <img
              style={{ width: "auto", maxWidth: '100%', margin: "0 auto" }}
              src={image}
              alt="Uploaded Icon"
            />
          )}
        </div>
        <p className="popup_subtitle">
          Copyright and Trademark Acknowledgment - By checking the box below,
          you confirm that you hold the appropriate rights or ownership for the
          logos, images, and text you're uploading. You agree to indemnify
          Savant Playback against any legal repercussions related to your
          upload.
        </p>
        <div className="agreement-check" style={{ marginTop: "10px" }}>
          <input
            type="checkbox"
            id="agreement-check"
            className="custom-checkbox"
            value={checkbox}
            onChange={(e) => setCheckbox(e.target.checked)}
          />
          <label htmlFor="agreement-check">
            I agree to the terms and conditions of use to utilize this
            image/logo.
          </label>
        </div>
        <div className="popup_button">
          <input
            ref={myInput}
            type="file"
            className="popup_uploadInput"
            accept="image/*"
            onChange={handleImageUpload}
          ></input>
          <button
            className="popup_save"
            onClick={(event) => {
              sendPhoto();
            }}
            disabled={!checkbox}
          >
            <img src={save} alt="popup_save_input_result" />
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default PopupRow;
