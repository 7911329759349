export const useTotalPrice = ({
  templateData,
  priceList,
  data,
  logo,
  leftLogo,
  centerLogo,
  rightLogo,
}) => {
  return () => {
    if (templateData && priceList) {
      const templateName = templateData?.name;
      const templatePrice = priceList[0].plates.find(
        ({ index }) => index.split("-")[0] === templateName.split("-")[0]
      );
      const slotPrice = Number(priceList[1].engravedSlotPrice);
      const filledCellsCount = data.filter(
        (item) => item.name !== -1 && item.name !== ""
      ).length;
      const priceOfFilledCells = filledCellsCount * slotPrice;
      const priceLeftLogo = leftLogo ? Number(priceList[3].engravedLogoRegular) : 0;
      const priceRightLogo = rightLogo ? Number(priceList[3].engravedLogoRegular)  : 0;
      const priceCenterLogo = centerLogo ? Number(priceList[2].engravedLogoLarge) : 0;
      return {
        totalPrice: (
          parseFloat(templatePrice.price) +
          priceOfFilledCells +
          priceLeftLogo +
          priceRightLogo +
          priceCenterLogo
        ).toFixed(2),
        filledCellsCount,
        priceOfFilledCells,
        priceLeftLogo,
        priceCenterLogo,
        priceRightLogo,
        templatePrice: templatePrice.price,
        slotPrice,
      };
    } else {
      return { totalPrice: 0 };
    }
  };
};
